export const dataLayerPush = (layer) => {
    if (typeof window === 'undefined') {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(layer);
};

export function trackEvent(name, category, action, label, dimensions = {}) {
    dataLayerPush({
        event: name,
        category: category,
        action: action,
        label: label,
        ...dimensions,
    });
}
