import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Btn from '../../components/Btn';  // Added for css precedence
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { themes } from '../../themes/ThemeContext';
import { getCookie, setCookie, removeCookie } from '../../utils/Cookie';
import { dataLayerPush } from '../../utils/DataLayerHelpers';
import Theme from '../../themes/Theme';
import CSRFContext from '../../security/CSRFContext';
import stylesNordr from './BasePage.nordr.module.scss'
import stylesFolkhem from './BasePage.folkhem.module.scss'

const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));
const BannerGlobal = dynamic(() => import('../../components/BannerGlobal'));
const CookieBar = dynamic(() => import('../../components/CookieBar'));

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class BasePage extends React.PureComponent {
    render() {
        const {
            cookieBar,
            footer,
            header,
            pageType,
            user,
            hideTopNav,
            hideFooter,
            bannerGlobal,
            siteSettings,
            seo,
            wagtailUserbar,
        } = this.props;

        const {
            htmlTitle,
            description,
            ogTitle,
            ogDescription,
            ogUrl,
            ogImage,
            twitterTitle,
            twitterDescription,
            twitterUrl,
            twitterImage,
            canonicalLink,
            robots,
            facebookPageId,
        } = seo;

        const { theme } = siteSettings;
        const layout = this.props.children
            ? this.props.children.props.layout
            : null;

        const styles = getStyles(theme);

        return (
            <>
                <Head>
                    <title>{htmlTitle}</title>

                    <link rel="preconnect dns-prefetch" href="https://p.typekit.net" />
                    <link rel="preconnect dns-prefetch" href="https://use.typekit.net" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="http://tb.de17a.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://static.hotjar.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://www.googletagmanager.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://www.google-analytics.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://www.facebook.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://connet.facebook.com" crossOrigin="true" />
                    <link rel="preconnect dns-prefetch" href="https://org-997.chat.kundo.se" crossOrigin="true" />

                    {!!description && (
                        <meta name="description" content={description} />
                    )}
                    {!!ogTitle && (
                        <meta property="og:title" content={ogTitle} />
                    )}
                    {!!ogDescription && (
                        <meta
                            property="og:description"
                            content={ogDescription}
                        />
                    )}
                    {!!ogUrl && (
                        <meta property="og:url" content={ogUrl} />
                    )}
                    {!!ogImage && (
                        <meta property="og:image" content={ogImage} />
                    )}
                    {!!twitterTitle && (
                        <meta property="twitter:title" content={twitterTitle} />
                    )}
                    {!!twitterDescription && (
                        <meta property="twitter:description" content={twitterDescription} />
                    )}
                    {!!twitterUrl && (
                        <meta property="twitter:url" content={twitterUrl} />
                    )}
                    {!!twitterImage && (
                        <meta property="twitter:image" content={twitterImage} />
                    )}
                    {!!canonicalLink && (
                        <link rel="canonical" href={canonicalLink} />
                    )}
                    {!!robots && (
                        <meta name="robots" content={robots} />
                    )}

                    {!!facebookPageId && (
                        <meta property="fb:pages" content={facebookPageId} />
                    )}

                    <link rel="apple-touch-icon" sizes="180x180" href={`/favicons/${theme}/apple-touch-icon.png`} />
                    <link rel="icon" type="image/png" sizes="32x32" href={`/favicons/${theme}/favicon-32x32.png`} />
                    <link rel="icon" type="image/png" sizes="16x16"  href={`/favicons/${theme}/favicon-16x16.png`} />
                    <link rel="mask-icon"  href={`/favicons/${theme}/safari-pinned-tab.svg`} color="#ffffff" />

                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                </Head>
                <Theme name={theme}>
                    <div
                        className={classNames(
                            styles['BasePage'],
                            styles['BasePage--Wrapper'],
                            styles['BasePage--Layout-'+layout],
                        )}>
                        {!hideTopNav && (
                            <header>
                                {header && (
                                    <Header
                                        {...header}
                                        user={user}
                                        layout={layout}
                                    />
                                )}
                            </header>
                        )}

                        {cookieBar && <CookieBar {...cookieBar} />}
                        {bannerGlobal && <BannerGlobal {...bannerGlobal} />}

                        <main className="Container">
                            {this.props.children}
                        </main>

                        {!hideFooter && Object.keys(footer).length > 0 && (
                            <Footer
                                {...footer}
                                layout={layout}
                                siteSetting={siteSettings}
                                modifiers={["WithinPageType"+pageType]}
                            />
                        )}
                    </div>
                </Theme>
                {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
            </>
        );
    }
}

BasePage.propTypes = {
    hideTopNav: PropTypes.bool,
    hideFooter: PropTypes.bool,
    cookieBar: PropTypes.object,
    footer: PropTypes.object,
    header: PropTypes.object,
    pageType: PropTypes.string,
    children: PropTypes.object,
    bannerGlobal: PropTypes.shape({
        buttonText: PropTypes.string,
        cookieId: PropTypes.string,
        wysiwyg: PropTypes.string,
        buttonLink: PropTypes.string,
        theme: PropTypes.string,
    }),
    user: PropTypes.shape({
        name: PropTypes.string,
        profile: PropTypes.shape({
            href: PropTypes.string,
        }),
        login: PropTypes.shape({
            href: PropTypes.string,
        }),
        logout: PropTypes.shape({
            href: PropTypes.string,
        }),
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
    }),
};

BasePage.defaultProps = {
    footer: {},
    hideTopNav: false,
    hideFooter: false,
};

const withCSRFToken = ComposedComponent => (props) => {
    return (
        <CSRFContext.Provider value={props.csrfToken}>
            <ComposedComponent {...props} />
        </CSRFContext.Provider>
    );
}

const withUserTracking = ComposedComponent =>
    class extends React.PureComponent {
        static defaultProps = {
            user: null,
        }

        static propTypes = {
            user: PropTypes.shape({
                azureAdId: PropTypes.string,
            }),
        };

        attemptToLogoutUser() {
            if (!getCookie('userIDTracked')) {
                return;
            }

            removeCookie('userIDTracked');
            dataLayerPush({ userID: null });
        }

        componentDidMount() {
            if (!this.props.user) {
                this.attemptToLogoutUser();
                return;
            }

            if (!this.props.user.azureAdId) {
                this.attemptToLogoutUser();
                return;
            }

            const { azureAdId } = this.props.user;

            if (getCookie('userIDTracked') === azureAdId) {
                return;
            }

            dataLayerPush({ userID: azureAdId });
            setCookie('userIDTracked', azureAdId);
        }

        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    };

export default withCSRFToken(withUserTracking(BasePage));
