function setCookie(cname, cvalue, expirationDays) {
    expirationDays = expirationDays || 365;
    let d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function removeCookie(cname) {
    let expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = cname + '=;' + expires + ';path=/';
}

const getCookie = (name) => {
    name = name + '=';
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let part = cookie[i];
        while (part.charAt(0) === ' ') {
            part = part.substring(1);
        }
        if (part.indexOf(name) === 0) {
            return part.substring(name.length, part.length);
        }
    }
    return '';
};

export { setCookie, getCookie, removeCookie };
